import React from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import Spinner from 'react-spinner-material';
import GridContainer from '../components/Grid/GridContainer';

const email = ({ data }) => {
    console.log(data);

    return (
        <GridContainer justify="center" style={styles.container}>
            <Spinner
                visible
                color="white"
                stroke={10}
                radius={200}
            />
        </GridContainer>
    );
};

const styles = {
    container: {
        marginTop: 100,
        marginBottom: 100,
    },
};

export default email;
